<template>
  <div>
    <aad-title>Speakers</aad-title>

    <aad-loader v-if="!allSpeakers || allSpeakers.length < 1"></aad-loader>

    <div v-else>
      <div>
        <h2>Search Speakers <span v-if="searchTerm">- Filtered</span></h2>
        <input type="text" v-model="searchTerm" class="form-control mb-3" />
        <button class="btn btn-primary-solid mb-2" @click="clearSearchTerm" v-if="searchTerm">
          View All
        </button>
        <hr />
      </div>

      <div class="row" v-if="filteredSpeakers && filteredSpeakers.length > 0">
        <div class="col-lg-12">
          <div class="card-deck" id="speaker-card-deck">
            <div
              class="card card-lg"
              v-for="speaker in filteredSpeakers"
              :key="speaker.id"
            >
              <router-link
                :to="{ name: 'SpeakerDetails', params: { id: speaker.id } }"
              >
                <img
                  class="card-img-top speaker-image"
                  :src="speaker.PhotoURL || getSpeakerPicture(speaker.PersonId)"
                  :alt="'Image of ' + speaker.DisplayName"
                  @error="fixImage"
                />
              </router-link>
              <router-link
                :to="{ name: 'SpeakerDetails', params: { id: speaker.id } }"
                class="card-title card-title-lg"
              >
                <div class="card-body">
                  {{ speaker.DisplayName }}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <h3>There are no speakers with your search terms.</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  mounted() {
    this.getSpeakers();
  },
  metaInfo: {
    title: "Speakers",
  },
  data() {
    return {
      searchTerm: "",
    };
  },
  methods: {
    getSpeakers() {
      this.$store.dispatch("loadSpeakers");
    },
    fixImage(e) {
      // If the image breaks while loading (Very likely), then replace it with the blank
      e.target.src = require("@/assets/loginicon.jpg");
    },
    getSpeakerPicture(speakerId) {
      let meetingCode = this.$store.state.meetingCode;
      let url = `https://lms.aad.org/MeetingSpeaker/GetPhoto?eventId=${meetingCode}&personId=${speakerId}`;
      return url;
    },
    clearSearchTerm() {
      this.searchTerm = "";
    },
  },
  computed: {
    ...mapGetters(["allSpeakers"]),
    filteredSpeakers() {
      return this.allSpeakers.filter((speaker) => {
        return (
          speaker.DisplayName.toLowerCase().includes(
            this.searchTerm.toLowerCase()
          ) ||
          speaker.Sessions.some((session) => {
            return session.SessionCode.toLowerCase().includes(
              this.searchTerm.toLowerCase()
            );
          })
        );
      });
    },
  },
};
</script>

<style>
.speaker-image {
  max-width: 100%;

  object-fit: cover;
  max-height: 250px;
}

#speaker-card-deck .card {
  min-width: 21%;
}
</style>
